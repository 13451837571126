import { AppConfig } from 'app-config'
import { injectBrowserValues } from '@newcross-tech/app-config-core'

bootstrap()

async function bootstrap() {
  const { values: appConfigValues } = await injectBrowserValues()

  AppConfig.initialize(appConfigValues)

  await import('./main')
}
